import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({ description }) => (
	<div className="mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
		<div className="row align--center">
			<div className="col c10--md c9--lg c7--xl">
				<div className="sans--18 sans--24--md line-break rich-text ">
					<BlockContent blocks={description} serializers={richText} />
				</div>
			</div>
		</div>
	</div>
);
