import React, { useState, useEffect } from 'react';
import QueueVideoPlayer from './QueueVideoPlayer';

import cookie from 'js-cookie';

const QueueVideosContainer = ({ list, queueSlug, color }) => {
	const [videosWatched, setVideosWatched] = useState(0);

	const QUEUE_KEY = queueSlug;

	const setVideoAsWatched = videoIndex => {
		setVideosWatched(videoIndex);
		cookie.set(QUEUE_KEY, videoIndex, {
			expires: 28,
		});
	};

	useEffect(() => {
		const cachedVideosWatched = cookie.get(QUEUE_KEY);
		cachedVideosWatched && setVideosWatched(cachedVideosWatched);
	}, []);

	return (
		<section className="videos">
			{list?.map((video, index) => (
				<QueueVideoPlayer
					key={video._key}
					length={list.length}
					{...video}
					index={index}
					videosWatched={videosWatched}
					setVideoAsWatched={setVideoAsWatched}
					color={color}
				/>
			))}
		</section>
	);
};;

export default QueueVideosContainer;
