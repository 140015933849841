import React from 'react';

import FullWidthImage from './FullWidthImage';
import QueueRichText from './QueueRichText';
import PullQuote from './PullQuote';
import QueueArtworkDouble from './QueueArtworkDouble';
import QueueArtworkSingle from './QueueArtworkSingle';

const isProduction = process.env.NODE_ENV !== 'development';

export default ({ components = [] }) => (
	<React.Fragment>
		{components && (
			<React.Fragment>
				{components.map((component, index) => {
					switch (component._type) {
						case 'richText':
							return (
								<QueueRichText
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'pullQuote':
							return (
								<PullQuote
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'fullWidthImage':
							return (
								<FullWidthImage
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'selectedArtworkDouble':
							return (
								<QueueArtworkDouble
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'selectedArtworkSingle':
							return (
								<QueueArtworkSingle
									index={index}
									key={component._key}
									{...component}
								/>
							);
						default:
							console.log(!isProduction && component);
							return (
								<React.Fragment>
									{!isProduction && (
										<p className="mt2 mb2 sans--24">
											Missing type : {component._type}
										</p>
									)}
								</React.Fragment>
							);
					}
				})}
			</React.Fragment>
		)}
	</React.Fragment>
);
