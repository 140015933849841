import React, { Fragment, useContext, useState } from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import QueueIcon from '../components/icons/QueueIcon';
import QueueTitle from '../components/QueueTitle';
import QueueVideosContainer from '../components/QueueVideosContainer';
import QueueComponentList from '../components/QueueComponentList';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import cx from 'classnames';
import sanityImage from '../util/sanityImage';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover';
import scroll from '../util/scrollHelper'

const Anatomy = ({ pageContext, location }) => {
	const [hoverRef, isHovered] = useHover();

	const {
		seo = {},
		color,
		title,
		subtitle,
		startDate,
		endDate,
		queueHero,
		description,
		episodesTitle,
		episodesImage,
		list,
		artworkTitle,
		artworkImage,
		content,
		inquire,
		...rest
	} = pageContext;

	const metaTitle =
		seo?.metaTitle || `Queue: ${title} | Library Street Collective`;
	const openGraphTitle =
		seo?.openGraphTitle || `Queue: ${title} | Library Street Collective`;
	const twitterTitle =
		seo?.twitterTitle || `Queue: ${title} | Library Street Collective`;

	const queueNav = (
		<Reveal className="row align--center mt5 mt10--lg ">
			<div className="reveal__slide reveal__delay--1 col c10--md c9--lg c7--xl">
				<nav className='queue__nav'>

					<a
						className="queue__nav-link sans--24 sans--36--md "
						style={{color: color}}
						onClick={e => {
							e.preventDefault();
							scroll('queue-episodes');
						}}
						title="Link to artist's works"
						href="#queue-episodes"
					>
						<span>

						Episodes
						</span>
					</a>
					<a
						className="queue__nav-link sans--24 sans--36--md "
						style={{color: color}}
						onClick={e => {
							e.preventDefault();
							scroll('queue-artwork');
						}}
						title="Link to artist's works"
						href="#queue-artwork"
					>
						<span>

						Artworks
						</span>
					</a>
				</nav>
			</div>
		</Reveal>
	);

	const heroSection = queueHero && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				<div className="pr">
					<picture
						ref={hoverRef}
						className="db x bb--black artwork--full-container"
					>
						<source
							srcSet={sanityImage(queueHero.url, { w: 2400 })}
							media="(min-width: 1800px)"
						/>
						<source
							srcSet={sanityImage(queueHero.url, { w: 2000 })}
							media="(min-width: 1600px)"
						/>
						<source
							srcSet={sanityImage(queueHero.url, { w: 1600 })}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={sanityImage(queueHero.url, { w: 1200 })}
							media="(min-width: 800px)"
						/>
						<source
							srcSet={sanityImage(queueHero.url, { w: 1000 })}
							media="(min-width: 600px)"
						/>
						<img
							alt={
								queueHero.alt ||
								title ||
								'QUEUE | Library Street Collective '
							}
							className={cx('db queue__hero', {
								hovered: isHovered,
							})}
							src={sanityImage(queueHero.url, { w: 800 })}
						/>
					</picture>
				</div>

				<aside
					id="queue-intro"
					className="mt2 mt5--lg grid-container contained tc"
					style={{ color }}
				>
					<QueueTitle
						color={color}
						title={title}
						startDate={startDate}
						endDate={endDate}
					/>
				</aside>
			</section>
		</Reveal>
	);

	const introSection = description && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<div className="row align--center">
					<div className="col c10--md c9--lg c7--xl">
						<div
							className="sans--18 sans--24--lg line-break rich-text tc"
							style={{ color }}
						>
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</div>
						{queueNav}
					</div>
				</div>
			</section>
		</Reveal>
	);

	const episodesHeader = (
		<Reveal>
			<section
				id='queue-episodes'
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg pt5 pb5 pt10--md pb10--md"
				style={{
					...(episodesImage && {
						backgroundImage: `url(${episodesImage.url})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: '50%',
					}),
				}}
			>
				<div className="grid-container contained">
					<h2
						style={{ color }}
						className="queue__section-title uppercase bold tc"
					>
						{episodesTitle}
					</h2>
				</div>
			</section>
		</Reveal>
	);

	const queueSlug = location.pathname.split('/exhibitions/')[1];

	const videoSections = (
		<Reveal>
			<section
				style={{
					color: color,
				}}
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained"
			>
				<QueueVideosContainer
					list={list}
					queueSlug={queueSlug}
					color={color}
				/>
			</section>
		</Reveal>
	);

	const artworksHeader = (
		<Reveal>
			<section
				id="queue-artwork"
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg pt5 pb5 pt10--md pb10--md"

				style={{
					backgroundColor: color,
					...(artworkImage && {
						backgroundImage: `url(${artworkImage.url})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: '50%',
					}),
				}}
			>
				<div className="grid-container contained">
					<h2
						style={{ color: artworkImage ? color : 'white' }}
						className="queue__section-title uppercase bold tc"
					>
						{artworkTitle}
					</h2>
				</div>
			</section>
		</Reveal>
	);

	const artworksSection = content && (
		<Reveal>
			<section
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg"
				style={{ color }}
			>
				<QueueComponentList components={content} />
			</section>
		</Reveal>
	);

	const mailtoLink =
		inquire &&
		`mailto:info@lscgallery.com?subject=${inquire?.subject}&body=${
			inquire.body && inquire.body
		}`;

	const inquireSection = inquire && (
		<div
			style={{ color }}
			id="queue-contact"
			className="mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg grid-container contained"
		>
			<a href={mailtoLink} className=" tc x db tile--large ">
				<span className="sans--24 sans--36--md sans--48--lg">
					{(inquire && inquire.linkText) ||
						`Inquire about available works by ${title}`}
				</span>
			</a>
		</div>
	);

	return (
		<Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			{heroSection}
			{introSection}
			{episodesHeader}
			{videoSections}
			{artworksHeader}
			{artworksSection}
			{inquireSection}
		</Fragment>
	);
};

export default Anatomy;
