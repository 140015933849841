import React from 'react';

export default ({ quote, source }) => (
	<div className="grid-container contained mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg">
		<p className="sans--24 sans--36--md sans--48--lg">“{quote}”</p>
		{source && (
			<p className="sans--18 sans--24--md tr uppercase mt2 mt5--lg">
				— {source}
			</p>
		)}
	</div>
);
