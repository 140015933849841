import React from 'react';

import useHover from '../util/useHover'
import cx from 'classnames';

export default ({ alt, url }) => {
	const [hoverRef, isHovered] = useHover();

	return (
		<picture ref={hoverRef} className="db x bt--black bb--black artwork--full-container">
			<source
				srcSet={`${url}?w=2000&auto=format&q=100`}
				media="(min-width: 1200px)"
			/>
			<source
				srcSet={`${url}?w=1200&auto=format&q=100`}
				media="(min-width: 1000px)"
			/>
			<source
				srcSet={`${url}?w=800&auto=format`}
				media="(min-width: 600px)"
			/>
			<img
				alt={alt || 'Image courtesy of the artist'}
				className={cx("db artwork--full", {
					'hovered': isHovered
				})}
				src={`${url}?w=600&auto=format`}
			/>
		</picture>
	)
};
