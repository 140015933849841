import React, { useState, useEffect, useRef } from 'react';
import Reveal from './Reveal';
import ReactPlayer from 'react-player/lazy';
import { Player } from '@lottiefiles/react-lottie-player';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import useIntersection from '../util/useIntersection';

import cx from 'classnames';

import line_01 from '../../static/animations/line_01.json';
import line_02 from '../../static/animations/line_02.json';
import line_03 from '../../static/animations/line_03.json';
import line_04 from '../../static/animations/line_04.json';

const QueueVideoPlayer = ({
	index,
	length,
	color,
	videosWatched,
	setVideoAsWatched,
	_key,
	title,
	lockedText,
	description,
	videoID,
}) => {
	// const [canPlayVideo, setCanPlayVideo] = useState(videosWatched >= index);
	// const [duration, setDuration] = useState(videosWatched >= index);
	// const [isPlaying, setIsPlaying] = useState(false);

	const lottieRef = useRef();
	const target = useRef(null);

	const isIntersected = useIntersection(target, {
		rootMargin: '-10%',
		once: true,
	});

	// useEffect(() => {
	// 	setCanPlayVideo(videosWatched >= index);
	// }, [videosWatched]);

	useEffect(() => {
		if (isIntersected) lottieRef.current.play();
	}, [isIntersected,]);

	const isEven = index % 2 === 0;


	return (
		<Reveal>
			<section className="reveal__slide reveal__delay--1 mt5 mb5">
				<div
					className={cx('video__tile', {
						'video__tile--left': index % 2 !== 0,
						'video__tile--right': index % 2 === 0,
					})}
				>
					<div
						className={cx('video__tile-interior', {
							'video__tile-interior--left': !isEven,
							'video__tile-interior--right': isEven,
						})}
					>
						<p className="bold sans--24 sans--36--md sans--48--lg mb2">
							<strong>Episode {index + 1}</strong>
						</p>
						{videoID && (
							<div className="video__tile-player-wrapper pr">
								<div className="video__tile-player">
									<ReactPlayer
										// onDuration={e => setDuration(e)}
										// onPlay={() => setIsPlaying(true)}
										// onPause={() => setIsPlaying(false)}
										// onEnded={() => setIsPlaying(false)}
										// onProgress={({ playedSeconds }) => {
										// 	if (isPlaying) {
										// 		if (
										// 			playedSeconds >=
										// 			duration / 10
										// 		) {
										// 			setVideoAsWatched(
										// 				index + 1
										// 			);
										// 		}
										// 	}
										// }}
										style={{
											position: 'absolute',
											inset: 0,
										}}
										height="100%"
										width="100%"
										url={`https://vimeo.com/${videoID}`}
										controls={true}
										playsinline
									/>
								</div>
								{/* {!canPlayVideo && lockedText && (
									<div className="video__tile-player-wrapper--disabled sans--14 sans--18--md rich-text">
										<BlockContent
											blocks={lockedText}
											serializers={richText}
										/>
									</div>
								)} */}

								<div
									style={{ backgroundColor: color }}
									className={cx(
										'video__tile-description',
										{
											'video__tile-description--left':
												index % 2 !== 0,
											'video__tile-description--right':
												index % 2 === 0,
										}
									)}
								>
									<p className="sans--18 sans--24--md sans--32--lg">
										{title}
									</p>
									{description && (
										<div className="mt1 sans--14 sans--18--md rich-text">
											<BlockContent
												blocks={description}
												serializers={richText}
											/>
										</div>
									)}
								</div>

							</div>
						)}
						<div style={{ color: color }} className="hide--lg mt2">
							<p className="sans--18 sans--24--md sans--32--lg">
								{title}
							</p>
							{description && (
								<div className="mt1 sans--14 sans--18--md rich-text">
									<BlockContent
										blocks={description}
										serializers={richText}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{index < length - 1 && (
				<div className="video__line-container">
					<img
						src="/images/queue__line.png"
						className={cx('queue__line-bg', {
							flip: isEven,
						})}
					/>
					<div
						ref={target}
						className={cx('video__line', {
							flip: isEven,
						})}
					>
						<Player
							ref={lottieRef}
							src={line_04}
							style={{ stroke: color }}
							keepLastFrame
						/>
					</div>
				</div>
			)}
		</Reveal>
	);
};

export default QueueVideoPlayer;
