import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import pill_64p from '../../static/animations/pill_64p.json';
import pill_128p from '../../static/animations/pill_128p.json';

import dateHelper from '../util/dateHelper';

const QueueTitle = ({ title, color, startDate, endDate }) => {
	return (
		<div className="row align--center">
			<div className="pill-container col c10--md c9--lg">
				<div className="pill-container__animation pill-container__animation--mobile">
					<Player
						autoplay
						loop
						src={pill_64p}
						style={{ stroke: color }}
					/>
				</div>

				<h1 className="pill-container__title x tc">
					QUEUE: {title}
				</h1>
			</div>
			{endDate && startDate && (
				<p className="mt2 mt5--lg tc sans--14 sans--18--md sans--24--lg">
					{dateHelper(startDate)} — {dateHelper(endDate)}
				</p>
			)}
		</div>
	);
};

export default QueueTitle;
